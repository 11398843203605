import React, { Component } from "react";
import Header from "./Header";
import Banner from "./Banner";
import Social from "./Social";
import MapAndAddress from "./MapAndAddress";
import ContactForm from "./ContactForm";
import Post from "./Post";
import Contact from "../wrappers/Contact";
import Footer from "../wrappers/Footer";
import { whatisSamsara } from "../pages";
import "../css/style.css";

class Samsara extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner smaller={true} text={"What is Samsara?"} button={false} />
        <div className="body">
          <div>
            <Post text={whatisSamsara.text} />
            <Social />
            <Contact>
              <div>
                <ContactForm />
                <MapAndAddress />
              </div>
            </Contact>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Samsara;
