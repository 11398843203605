import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Text from "../components/Text";
import { whatisSamsara, therapy } from "../pages";

class ListServices extends Component {
  render() {
    return (
      <div className="services">
        <h1>Why Samsara?</h1>
        <div className="container">
          <Row>
            <Col md={6} className="verticalMargins">
              <div className="center serviceFloat">
                <h3>What is Samsara?</h3>
                <div className="margin80 ">
                  <Text size="18px">
                    {whatisSamsara.text
                      .substring(0, 415)
                      .replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "") + "..."}
                  </Text>
                </div>
                <Link to="samsara" className="serviceFloatButtons">
                  Read More
                </Link>
              </div>
            </Col>
            <Col md={6} className="verticalMargins">
              <div className="center serviceFloat">
                <h3>Therapy</h3>
                <div className="margin80 ">
                  <Text size="18px">
                    {therapy.text
                      .substring(0, 460)
                      .replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "") + "..."}
                  </Text>
                </div>
                <Link to="therapy" className="serviceFloatButtons">
                  Read More
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ListServices;
