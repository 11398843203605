import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Header from "./Header";
import Banner from "./Banner";
import AboutUs from "./About";
import Social from "./Social";
import MapAndAddress from "./MapAndAddress";
import ContactForm from "./ContactForm";
import ListServices from "./ListServices";
import Contact from "../wrappers/Contact";
import Footer from "../wrappers/Footer";
import { pages } from "../pages";

class Home extends Component {
  render() {
    return (
      <div className="App">
        <Banner text={pages.page.home.banner} button={true} />
        <Header />
        <div className="body">
          <div>
            <ListServices />
            <AboutUs />
            <Social />
            <Contact>
              <div>
                <ContactForm />
                <MapAndAddress />
              </div>
            </Contact>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Home);
