import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../css/style.css";
import { aboutme } from "../pages.js";
import Text from "./Text";

class AboutUs extends Component {
  render() {
    return (
      <div className="aboutme">
        <div className="container">
          <Row>
            <Col md={6}>
              <img
                src={require("../images/headshot.jpg")}
                alt="Samara"
                className="headshot"
              />
            </Col>
            <Col md={6}>
              <h1>About Me</h1>
              <Text size="19px">
                {aboutme.text
                  .substring(0, 550)
                  .replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "") + " ..."}
              </Text>
              <Link to="aboutme" className="letsTalkDark">
                Read More
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AboutUs;
