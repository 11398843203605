import React, { Component } from "react";
import { Col } from "react-bootstrap";
import "../css/style.css";

class MapAndAddress extends Component {
  render() {
    return (
      <Col xs={12} md={6} className="relative">
        <iframe
          title="Map"
          className="mapIframe"
          src="https://maps.google.com/maps?q=1445%20Portage%20Ave&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
        <div className="clear no-padding"></div>
      </Col>
    );
  }
}

export default MapAndAddress;
