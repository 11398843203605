import React, { Component } from "react";
import { Col } from "react-bootstrap";
import "../css/style.css";
import Text from "./Text";

const errorState = {
  name: "",
  email: "",
  telephone: "",
  message: "",
};
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      telephone: "",
      message: "",
      errorState: errorState,
      emailSent: false,
    };
  }

  componentWillMount() {}

  validateForm = (formState) => {
    console.log(formState);
    if (
      formState.email !== "" &&
      formState.email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ) &&
      formState.name !== "" &&
      formState.telephone !== "" &&
      formState.message !== ""
    )
      return true;
    else return false;
  };

  handleContact = (e) => {
    e.preventDefault();
    if (this.validateForm(this.state))
      fetch(
        "https://r4hc9rb61l.execute-api.us-east-1.amazonaws.com/test/send",
        {
          method: "POST",
          body: JSON.stringify(this.state),
          mode: "no-cors",
          header: {
            "Access-Control-Allow-Origin": "*",
          },
        },
      ).then(
        this.setState({
          emailSent: true,
        }),
      );
    else alert("Please fill in all the fields!");
  };

  render() {
    return (
      <Col md={6} className="contactForm">
        <div className="margin80">
          <h2 className="whiteText">
            <Text size={22}>
              {!this.state.emailSent
                ? "Send us a confidential referral and we will respond in a timely manner."
                : "Thank you for sending us a message. We will get back to you as soon as possible."}
            </Text>
          </h2>
          <div>
            {!this.state.emailSent ? (
              <form onSubmit={this.handleContact}>
                <p>
                  <input
                    type="text"
                    className={this.state.errorState.name ? "errorField" : ""}
                    placeholder="Name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </p>
                <span className="error">{this.state.errorState.name}</span>
                <p>
                  <input
                    type="email"
                    className={this.state.errorState.email ? "errorField" : ""}
                    placeholder="Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </p>
                <span className="error">{this.state.errorState.email}</span>
                <p>
                  <input
                    type="tel"
                    className={this.state.errorState.phone ? "errorField" : ""}
                    placeholder="Telephone"
                    onChange={(e) =>
                      this.setState({ telephone: e.target.value })
                    }
                  />
                </p>
                <span className="error">{this.state.errorState.telephone}</span>
                <textarea
                  className={this.state.errorState.message ? "errorField" : ""}
                  cols="25"
                  rows="8"
                  placeholder="I would like..."
                  onChange={(e) => this.setState({ message: e.target.value })}
                ></textarea>
                <span className="error">{this.state.errorState.message}</span>
                <input
                  type="submit"
                  value="Let's Talk"
                  className="letsTalkDark"
                />
              </form>
            ) : (
              ""
            )}
          </div>
        </div>
      </Col>
    );
  }
}

export default ContactForm;
