import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import Home from "./components/Home";
import Mission from "./components/Mission";
import Letstalk from "./components/Letstalk";
import AboutMe from "./components/AboutMe";
import AboutUs from "./components/AboutUs";
import "./css/style.css";
import Samsara from "./components/Samsara";
import Therapy from "./components/Therapy";

class App extends Component {
  componentWillMount() {
    //window.scroll(0);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <ScrollToTop>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route path="/aboutus" component={AboutUs} />
            <Route path="/mission" component={Mission} />
            <Route path="/letstalk" component={Letstalk} />
            <Route path="/aboutme" component={AboutMe} />
            <Route path="/samsara" component={Samsara} />
            <Route path="/therapy" component={Therapy} />
          </ScrollToTop>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
