import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { initializeGA, registerPageView } from "./GAHelper";
class Header extends Component {
  componentDidMount() {
    initializeGA();
    registerPageView(this.props.location.pathname);
  }
  render() {
    const getCurrentPath = (currentLink) => {
      return (
        currentLink ===
        this.props.location.pathname.substring(
          1,
          this.props.location.pathname.length,
        )
      );
    };
    return (
      <div className="header">
        <div className="navbar">
          <Link to="home">
            <img
              className="logo"
              src={require("../images/SamsaraLogo.jpg")}
              alt="Samara Logo"
            />
          </Link>
          <div className="clear"></div>
          <div className="menu sticky">
            <ul className="nav">
              <Link to="home">
                <li className={getCurrentPath("home") ? "current" : ""}>
                  Home
                </li>
              </Link>
              <Link to="aboutus">
                <li className={getCurrentPath("aboutus") ? "current" : ""}>
                  About
                </li>
              </Link>
              <Link to="mission">
                <li className={getCurrentPath("mission") ? "current" : ""}>
                  Mission
                </li>
              </Link>
              <Link to="letstalk">
                <li className={getCurrentPath("letstalk") ? "current" : ""}>
                  Let's talk
                </li>
              </Link>
            </ul>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
