import React, { Component } from "react";
import Header from "./Header";
import Banner from "./Banner";
import Social from "./Social";
import MapAndAddress from "./MapAndAddress";
import ContactForm from "./ContactForm";
import Post from "./Post";
import Contact from "../wrappers/Contact";
import Footer from "../wrappers/Footer";
import { aboutme } from "../pages";
import { Col, Row } from "react-bootstrap";
import "../css/style.css";

class About extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner smaller={true} text={"About Me"} button={false} />
        <div className="body">
          <div className="margin80">
            <Row>
              <Col md={4}>
                <img
                  src={require("../images/headshot.jpg")}
                  alt="Samara"
                  className="headshotAboutMe"
                />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="letsTalkDark"
                  href="https://ca.linkedin.com/in/kara-moss-8572181b"
                >
                  LinkedIn
                </a>
              </Col>
              <Col md={8}>
                <Post text={aboutme.text} />
              </Col>
            </Row>
          </div>
          <Social />
          <Contact>
            <div>
              <ContactForm />
              <MapAndAddress />
            </div>
          </Contact>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
