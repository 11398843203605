import React, { Component } from "react";

class Text extends Component {
  componentWillMount() {}
  render() {
    var font = {
      fontSize: this.props.size,
    };
    return <div style={font}>{this.props.children}</div>;
  }
}

export default Text;
