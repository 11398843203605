import React, { Component } from "react";
import { Link } from "react-router-dom";

class Banner extends Component {
  render() {
    const style = {
      height: "50vh",
    };
    return (
      <div className="banner" style={this.props.smaller ? style : null}>
        <div className="bannerText">
          {this.props.text}
          <br />
          {this.props.button ? (
            <Link to="letstalk" className="letsTalkLight">
              Let's Talk
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Banner;
