import React, { Component } from "react";
import Header from "./Header";
import Banner from "./Banner";
import MapAndAddress from "./MapAndAddress";
import ContactForm from "./ContactForm";
import Contact from "../wrappers/Contact";
import Footer from "../wrappers/Footer";
import "../css/style.css";

class Letstalk extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner smaller={true} text={"Let's Talk"} button={false} />
        <div className="body">
          <Contact>
            <ContactForm />
            <MapAndAddress />
          </Contact>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Letstalk;
