import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "../css/style.css";

class Social extends Component {
  render() {
    return (
      <div className="social">
        <div className="margin80">
          <h1 className="white">Lets start your journey together</h1>
          <h4>Find me on social media</h4>
          <Row>
            <Col md={6} className="verticalMargins">
              <a
                className="letsTalkDark"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/pages/category/Therapist/Samsara-Therapy-738365809681784/"
              >
                <i className="icon ion-logo-facebook"></i>
              </a>
            </Col>
            <Col md={6} className="verticalMargins">
              <a
                className="letsTalkDark"
                target="_blank"
                rel="noopener noreferrer"
                href="https://ca.linkedin.com/in/kara-moss-8572181b"
              >
                <i className="icon ion-logo-linkedin"></i>
              </a>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Social;
