import React, { Component } from "react";

class Post extends Component {
  render() {
    const style = {
      fontSize: this.props.size,
    };
    return (
      <div className="margin80 post">
        <div
          style={style}
          dangerouslySetInnerHTML={{ __html: this.props.text }}
        ></div>
      </div>
    );
  }
}

export default Post;
